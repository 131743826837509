import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.0.0-canary.179_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_re_zcluavbzgn2pyb3yxd7tulihou/node_modules/next/dist/lib/metadata/metadata-boundary.js");
